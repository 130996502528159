import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './Initial/config.js';
import NavBar from './Menu.js';
import Footer from './Footer.js';
import Noticias from './Initial/noticias.js';
import NoticiaId from './Initial/NoticiasID.js';
import Retorno from './Initial/Retorno.js';
import RetornoId from './Initial/RetornoID.js';
import Error404 from './Initial/Error404.js';
import Home from './Initial/Home.js';
import Organigrama from './Initial/Organigrama.js';
import LosQueLlegan from './Initial/LosQueLlegan.js';
import LosQueCrecen from './Initial/LosQueCrecen.js';
import LosQueCumplen from './Initial/LosQueCumplen.js';
import Aniversarios from './Initial/Aniversarios.js';
import AlgoParaCelebrar from './Initial/AlgoParaCelebrar.js';
import Conoce from './Initial/Conoce.js';
import WorkApps from './Initial/WorkApps.js';
import PoliticasFormatos from './Initial/PoliticasFormatos.js';
import Finanzas from './Initial/Finanzas.js';
import Bienestar from './Initial/Bienestar.js';
import TuSalud from './Initial/TuSalud.js';
import OtrasCosasBuenas from './Initial/OtrasCosasBuenas.js';
import Vacantes from './Initial/Vacantes.js';
import ElCorcho from './Initial/ElCorcho.js';
import SalaDeLectura from './Initial/SalaDeLectura.js';
import Comprobaciones from './Initial/Comprobaciones.js';

class App extends Component {
  render() {
    return (
      <Router>
        <NavBar/>
        
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/noticias" component={Noticias} />
              <Route exact path="/noticia/:id" component={NoticiaId} />
              <Route exact path="/b2n" component={Retorno} />
              <Route exact path="/b2n/:id" component={RetornoId} />
              <Route exact path="/organigrama" component={Organigrama} />
              <Route exact path="/losquellegan" component={LosQueLlegan} />
              <Route exact path="/losquecrecen" component={LosQueCrecen} />
              <Route exact path="/losquecumplen" component={LosQueCumplen} />
              <Route exact path="/aniversarios" component={Aniversarios} />
              <Route exact path="/algoparacelebrar" component={AlgoParaCelebrar} />
              <Route exact path="/conocemejora" component={Conoce} />
              <Route exact path="/workapps" component={WorkApps} />
              <Route exact path="/politicas-y-formatos" component={PoliticasFormatos} />
              <Route exact path="/finanzas" component={Finanzas} />
              <Route exact path="/cultura-bienestar" component={Bienestar} />
              <Route exact path="/tu-salud" component={TuSalud} />
              <Route exact path="/otras-cosas-buenas" component={OtrasCosasBuenas} />
              <Route exact path="/vacantes" component={Vacantes} />
              <Route exact path="/el-corcho" component={ElCorcho} />
              <Route exact path="/sala-de-lectura" component={SalaDeLectura} />
              <Route exact path="/comprobaciones" component={Comprobaciones} />


              <Route path="*"  component={Error404} />
            </Switch>
            <Footer/>

      </Router>
    )
  }
}


export default (App)


