import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class Comprobaciones extends Component {

    render() {
        
        return (
            <>
                <div className="container-fluid bg-black" >
                    <br/><br/><br/><br/>
                    <h1 align="center" className="tituloPrincipal"><b>Comprobaciones (Meeting, Virtual , EBTA)</b></h1>
                </div>
                <br/><br/>
                
                <div className='container'>
                    <div className='row'>
                    <center><iframe width="640px" height="580px" src="https://forms.office.com/r/4FPvwU5v1U?embed=true" frameborder="0" marginwidth="0" marginheight="0"  style={{ border: "none", maxWidth: "100%", maxHeight: "100vh" }}  allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe></center>
                    </div>
                </div>
                <br/><br/>
                <center><a href='https://mccannet.mccann.com.mx/wp-content/uploads/2024/12/Non-PO-MX-facturas-sin-soporte-V2.0-1.xlsx' >Non PO MX facturas sin soporte V2.0 1</a></center>
                <br/><br/>
            </>
        )
    }
}
export default Comprobaciones;